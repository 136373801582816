<template>
    <div>
        <el-upload
            multiple
            :limit="limit"
            :disabled="disabled"
            list-type="picture-card"
            :accept="accept"
            :action="sign.host || ''"
            :data="ossData"
            :before-upload="handleBeforeUpload"
            :on-change="handleChange"
            :on-preview="handleImagePreview"
            :on-remove="handlkeOnRemove"
            :on-exceed="handleExceed"
            :file-list="fileList"
        >
            <i slot="default" class="el-icon-plus"></i>
        </el-upload>
        <div class="tips">
            <template v-if="accept">
                支持扩展名：{{ accept.trim().split(',').join(' ') }}
            </template>
            <template v-if="accept && size">,</template>
            <template v-if="size"> 文件大小不能超过{{ size }}M </template>
        </div>
        <el-image
            style="height: 0; width: 0; position: absolute; z-index: -1"
            ref="preview"
            :src="currentPreviewImage"
            :preview-src-list="previewList"
        ></el-image>
    </div>
</template>

<script>
import { getOssSign } from '@/api/common';
import { replaceWhiteSpace } from '@/utils/common';

export default {
    name: 'component-multiple-image-upload',
    props: {
        accept: {
            type: String,
            default: '',
        },
        limit: {
            type: Number,
            default: 0,
        },
        // 文件大小限制，单位为 MB
        size: {
            type: Number,
        },
        tips: {
            type: String,
        },
        // 传入的list，无需补全完整路径，会自动补全
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        //  1:task 任务(默认)    2:opus  作品
        ossType: {
            type: [Number, String],
            default: '',
        },
    },
    watch: {
        list: {
            handler(value) {
                this.fileList = value.map((item) => {
                    return {
                        name: item.name,
                        url: process.env.VUE_APP_OSS_HOST + item.url,
                    };
                });
            },
            immediate: true,
        },
    },
    computed: {
        previewList() {
            return this.fileList.map((file) => file.url);
        },
    },
    data() {
        return {
            sign: {},
            ossData: {},
            fileList: [],

            /*
                阿里云上传图片不会返回图片地址。因此上传之前，会先保存图片地址，成功后再从此读取
                格式  uid: url
            */
            urlMapping: {},

            showMsg: false,

            currentPreviewImage: '', // 当前被预览的图片地址
        };
    },
    async mounted() {
        await this.initOssData();
    },
    methods: {
        // 初始化
        async initOssData() {
            const [response, error] = await getOssSign(this.ossType);

            if (!error) {
                this.sign = response.data.sign;

                let {
                    host,
                    policy,
                    signature,
                    expired_in,
                    accessid: OSSAccessKeyId,
                } = response.data.sign;

                // host 以 / 结尾，则去掉斜杠
                if (host.endsWith('/')) {
                    this.sign.host = host.substring(0, host.length - 1);
                }

                // 根据本机时间设置过期时间
                this.sign.expire = Date.now() + expired_in * 1000;

                this.ossData = {
                    policy,
                    signature,
                    OSSAccessKeyId,
                };
            } else {
                this.$message.error(error.msg);
            }
        },
        async handleBeforeUpload(file) {
            // 检测文件格式
            if (this.accept) {
                const accepts = this.accept.split(',');
                const isValid = accepts.find((item) => {
                    return file.name.toLocaleLowerCase().endsWith(item.toLocaleLowerCase().trim());
                });
                if (!isValid) {
                    this.$message.error('格式错误！');
                    return Promise.reject();
                }
            }

            // 检测文件大小
            if (this.size && this.size * 1024 * 1024 < file.size) {
                if (!this.showMsg) {
                    this.showMsg = true;
                    this.$message({
                        message: '尺寸超出限制',
                        type: 'error',
                        onClose: () => {
                            this.showMsg = false;
                        },
                    });
                }
                return Promise.reject();
            }

            await this.initOssData();

            // 防止文件重名
            const dir = this.sign.dir;
            const now = Date.now();
            this.ossData.key = dir + '/' + now + '-' + replaceWhiteSpace(file.name);

            // 将图片 uid 和 其路径进行保存
            this.urlMapping[file.uid] = {
                name: now + '-' + file.name,
                url: '/' + this.ossData.key,
            };
            return Promise.resolve();
        },
        handleChange(file, fileList) {
            this.fileList = fileList;
            this.$emit('change', fileList);
        },
        handlkeOnRemove(file) {
            const index = this.fileList.findIndex((item) => item.name === file.name);
            this.fileList.splice(index, 1);
        },
        // 格式化 fileList
        formatFileList(fileList) {
            const result = [];
            for (let i = 0; i < fileList.length; i++) {
                const item = fileList[i];
                if (item.raw) {
                    if (item.status !== 'success') {
                        this.$message.warning('请等待文件上传完成');
                        return false;
                    }
                    result.push(this.urlMapping[item.uid]);
                } else {
                    result.push({
                        name: item.name,
                        url: item.url.replace(process.env.VUE_APP_OSS_HOST, ''),
                    });
                }
            }
            return result;
        },

        // el-image 会等待图片加载车成功，才将 img 标签渲染。
        // 下方代码，等待图片load ，然后 setTimeout 等待下一轮事件循环为合理操作，无需优化
        // el-image 触发图片预览的事件绑定在 img 标签上，因此成功获取图片元素后，触发其 click 事件
        handleImagePreview(file) {
            this.currentPreviewImage = file.url;
            const image = new Image();
            image.src = this.currentPreviewImage;
            image.onload = () => {
                setTimeout(() => {
                    this.$refs.preview.$el.getElementsByTagName('img')[0].click();
                });
            };
        },
        handleExceed() {
            this.$message.warning(`最多只能上传${this.limit}个文件`);
        },
        // 获取已上传图片的列表，如果有任何异常状态，则获取失败并提示
        getFiles() {
            return this.formatFileList(this.fileList);
        },
    },
};
</script>

<style lang="scss" scoped>
.tips {
    color: #979797;
    font-size: 14px;
}
.el-upload-list__item-thumbnail {
    width: 148px;
    height: 148px;
}
</style>
