<script>
import LkMultipleFileUpload from '@/components/LkMultipleFileUpload';
import ItemUpload from './ItemUpload.vue';
import ItemInput from './ItemInput.vue';

export default {
    name: 'delivery-upload-content',
    components: { LkMultipleFileUpload, ItemUpload, ItemInput },
    props: {
        // 交付物规则
        deliveryRules: {
            type: Object,
            default() {
                return {};
            },
        },
        deliveries: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    watch: {
        deliveries: {
            handler(value) {
                this.innerDeliveries = JSON.parse(JSON.stringify(value));
                this.initDeliveries = JSON.parse(JSON.stringify(value));
            },
            immediate: true,
        },
    },
    data() {
        return {
            innerDeliveries: {},
            initDeliveries: {},
        };
    },
    methods: {
        handleSubmit() {
            const keys = Object.keys(this.deliveryRules);
            for (let i = 0; i < keys.length; i++) {
                const id = keys[i];
                const rule = this.deliveryRules[id];
                console.log(rule);
                const { is_required, entry_type } = rule;
                const data = this.$refs[id].getData();
                if (entry_type === 6) {
                    if (!data && is_required) return;
                    this.innerDeliveries[id] = data.map((item) => item.url);
                } else {
                    this.innerDeliveries[id] = this.$refs[id].getData();
                }
            }
            this.$refs.deliveryForm.validate((valid) => {
                if (!valid) return false;
                const deliveries = Object.keys(this.innerDeliveries).map((deliveryId) => {
                    return {
                        delivery_id: deliveryId,
                        delivery_content: this.innerDeliveries[deliveryId],
                    };
                });
                this.$emit('submit', deliveries);
            });
        },
    },
    render() {
        // 表单配置
        const formData = {
            props: {
                labelWidth: '150px',
                labelPosition: 'left',
                model: this.innerDeliveries,
            },
            ref: 'deliveryForm',
        };
        return (
            <el-form class="delivery-upload-content" {...formData}>
                {Object.keys(this.deliveryRules).map((id) => {
                    const deliveryRule = this.deliveryRules[id];
                    switch (deliveryRule.entry_type) {
                        case 4:
                        case 5:
                            return (
                                <item-input
                                    ref={id}
                                    deliveryRule={deliveryRule}
                                    id={id}
                                    delivery={this.initDeliveries[id] || ''}
                                    type={{ 4: 'text', 5: 'textarea' }[deliveryRule.entry_type]}
                                    on={{
                                        change: (text) => {
                                            this.innerDeliveries[id] = text;
                                        },
                                    }}
                                ></item-input>
                            );
                        case 6:
                            return (
                                <item-upload
                                    ref={id}
                                    deliveryRule={deliveryRule}
                                    id={id}
                                    delivery={this.initDeliveries[id] || []}
                                ></item-upload>
                            );
                    }
                })}
                <el-form-item>
                    <el-button type="primary" size="small" on={{ click: this.handleSubmit }}>
                        确认提交
                    </el-button>
                </el-form-item>
            </el-form>
        );
    },
};
</script>
