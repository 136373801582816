<script>
export default {
    name: 'delivery-input-item',
    props: {
        deliveryRule: {
            type: Object,
            required: true,
        },
        id: {
            type: [String, Number],
        },
        delivery: {
            type: String,
            required: true,
        },
        type: {
            type: String,
        },
    },
    watch: {
        delivery: {
            handler(value) {
                this.text = value;
            },
            immediate: true,
        },
    },
    data() {
        return {
            text: '',
        };
    },
    methods: {
        getData() {
            return this.text;
        },
    },
    render() {
        // 当交付类型为输入类型的时候 word_types 下存储的数组为允许输出的类型
        const TYPE_STRING = {
            1: '数字',
            2: '中文',
            3: '英文',
        };

        //  word_types   1数字 2中文 3英文
        function genRegExp(types, min, max) {
            const REG_ITEM_MAP = {
                1: '0-9',
                2: '\\u4e00-\\u9fa5',
                3: 'a-z',
            };
            const range = types.map((type) => REG_ITEM_MAP[type]).join('');
            return new RegExp(`^[${range}]{${min},${max}}$`, 'i');
        }

        const { max_word, min_word, word_types } = this.deliveryRule.restrictions;

        // 输入规则
        let rules = {
            required: !!this.deliveryRule.is_required,
            trigger: 'blur',
        };
        // 当 word_types 字段不存在，则表示不限制输入
        if (word_types) {
            const typeString = word_types.map((type) => TYPE_STRING[type]).join('，');
            rules.message = `请输入 ${min_word}-${max_word} 个${typeString}`;
            rules.pattern = genRegExp(word_types, min_word, max_word);
        } else {
            rules.message = `请输入 ${min_word}-${max_word} 长度的字符`;
            rules.min = min_word;
            rules.max = max_word;
        }

        // form-item 配置
        const formItemData = {
            props: {
                label: this.deliveryRule.display_name,
                prop: this.id,
                rules,
            },
        };

        return (
            <el-form-item {...formItemData} key={this.id}>
                <el-input
                    type={this.type}
                    show-word-limit={true}
                    maxlength={max_word}
                    rows={Math.ceil(max_word / 50)}
                    placeholder={rules.message}
                    v-model={this.text}
                    on={{
                        change: () => {
                            this.$emit('change', this.text);
                        },
                    }}
                ></el-input>
            </el-form-item>
        );
    },
};
</script>
