<template>
    <div class="container">
        <!-- progress -->
        <!-- 步骤条盒子 -->
        <div class="progress-content">
            <!-- 步骤条背景进度条 -->
            <div class="progress" :class="'step' + step">
                <!-- TODO: 小圆球 到顶隐藏缺少数据 class已经定义为：isShow -->
                <span class="circular" :class="{ isShow: taskDetail.state === 6 }"></span>
            </div>
        </div>

        <!-- 任务流程模块 -->
        <div class="progress-move">
            <div class="progress-box" @click="handleChange('protocol', true)">
                <i class="icon"> </i>
                <span
                    class="text"
                    :class="{
                        active: activitedMoudel === 'protocol',
                    }"
                >
                    签订协议
                </span>
            </div>
            <div class="progress-box" @click="handleChange('init', initUseable)">
                <i class="icon" :class="{ disable: !initUseable }"></i>
                <span
                    class="text"
                    :class="{
                        active: activitedMoudel === 'init',
                        disable: !initUseable,
                    }"
                >
                    项目立项
                </span>
            </div>
            <div class="progress-box" @click="handleChange('proposal', proposalUseable)">
                <i class="icon" :class="{ disable: !proposalUseable }"></i>
                <span
                    class="text"
                    :class="{ active: activitedMoudel === 'proposal', disable: !proposalUseable }"
                >
                    设计提案
                </span>
            </div>
            <div class="progress-box" @click="handleChange('delivery', deliveryUseable)">
                <i class="icon" :class="{ disable: !deliveryUseable }"></i>
                <span
                    class="text"
                    :class="{
                        active: activitedMoudel === 'delivery',
                        disable: !deliveryUseable,
                    }"
                >
                    交付文件
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ['taskDetail'],

    computed: {
        // 协议模块是一直可用
        // 项目立项模块是否可用
        initUseable() {
            const state = this.taskDetail.taskData.state;
            return state === 4 || state === 5 || state === 6;
        },
        // 设计提案是否可用
        proposalUseable() {
            const state = this.taskDetail.taskData.state;
            return state === 5 || state === 6;
        },
        // 交付文件模块是否可用
        deliveryUseable() {
            const { state, check_type } = this.taskDetail.taskData;
            return (state === 5 || state === 6) && check_type !== 0;
        },

        // 进度条
        step() {
            const stepList = [true, this.initUseable, this.proposalUseable, this.deliveryUseable];
            return stepList.findIndex((item) => item === false);
        },

        // 当前被激活的模块
        activitedMoudel() {
            return this.taskDetail.activitedMoudel;
        },
    },

    methods: {
        // 更改模块的事件
        handleChange(step, isDisable) {
            if (isDisable) {
                this.$emit('change', step);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.progress-content {
    height: 12px;
    border-radius: 6px;
    background-color: #e2e2e2;
    .progress {
        position: relative;
        height: 100%;
        border-radius: 6px;
        background: url(~@/assets/images/progress.png) no-repeat;
        background-size: 100% 100%;
        &::before {
            content: '';
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            border-radius: 10px;
            animation: progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
        }
        @for $i from 0 through 4 {
            &.step#{$i} {
                width: 25% * $i;
            }
        }
        .circular {
            position: absolute;
            top: -5px;
            left: 100%;
            width: 22px;
            height: 22px;
            margin-left: -11px;
            background: #fff;
            border: 7.5px solid #668bfa;
            border-radius: 50%;
            &.isShow {
                display: none;
            }
        }
    }
}

.progress-move {
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    .progress-box {
        .icon {
            cursor: pointer;
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #679df8;
            margin: 8px auto;
            border: 3px solid #b6d0fc;
            &.disable {
                background: #f1f1f1;
                border: 3px solid #f6f6f8;
                cursor: not-allowed;
            }
        }
        .text {
            cursor: pointer;
            font-weight: bold;
            &.active {
                color: #679df8;
            }
            &.disable {
                color: #c1c0c6;
                cursor: not-allowed;
            }
        }
    }
}
@keyframes progress-active {
    0% {
        opacity: 0.1;
        width: 0;
    }

    20% {
        opacity: 0.3;
        width: 0;
    }

    to {
        opacity: 0;
        width: 100%;
    }
}
</style>
