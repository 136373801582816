<template>
    <div class="protocol-container">
        <!-- protocol -->
        <div class="module-title mb-10">签订协议</div>
        <i class="module-line"></i>

        <!-- 内部设计师不需要签订协议 -->
        <ul class="title-list" v-if="taskDetail.taskData.designer_type === 1">
            <li class="list-content">内部设计师无需签订协议</li>
        </ul>

        <!--
            其他设计师需要签订协议
            0: '待审核', 1: '审核中', 2: '已驳回', 3: '已通过', 4: '待签约', 5: '已签约',
            签约后，展示签约后的 sign_file
        -->
        <ul class="title-list" v-else>
            <li class="list-content">请等待邦女郎生成协议内容</li>
            <!--  -->
            <li
                class="list-content mt-10"
                v-if="[4].indexOf(taskDetail.taskData.protocol.state) > -1"
            >
                您的《洛客服务协议》已生成，请您尽快签订协议
                <div style="color: #e88989; margin-top: 20px">
                    <p>法律责任特别提示：</p>
                    <p>
                        请确保您的设计方案（包括但不限于图片、字体、设计图等）的原创性、合法性，不得抄袭，不得侵犯任何第三方的知识产权及其他合法权益（包括但不限于第三方的商标权、专利权、著作权等）。否则您将会承担因侵权产生的所有法律责任及赔偿责任。
                    </p>
                </div>
            </li>
            <li class="list-content mt-10" v-if="taskDetail.taskData.protocol.state === 5">
                <el-link
                    :underline="false"
                    target="_blank"
                    :href="taskDetail.taskData.protocol.sign_file"
                >
                    《洛客服务协议》
                </el-link>
                已签订，请等待邦女郎启动项目
            </li>
        </ul>
        <!-- 补充协议 -->
        <ul
            class="title-list"
            v-if="[4, 5].indexOf(taskDetail.taskData.replenish_protocol.state) > -1"
        >
            <li
                class="list-content mt-10"
                v-if="[4].indexOf(taskDetail.taskData.replenish_protocol.state) > -1"
            >
                您的《补充协议》已生成，请您尽快签订协议
            </li>
            <li
                class="list-content mt-10"
                v-if="taskDetail.taskData.replenish_protocol.state === 5"
            >
                <el-link
                    :underline="false"
                    target="_blank"
                    :href="taskDetail.taskData.replenish_protocol.sign_file"
                >
                    《补充协议》
                </el-link>
                已签订，请等待邦女郎启动项目
            </li>
        </ul>

        <p class="ml-20" v-if="taskDetail.taskData.protocol.state === 4">
            <el-button size="mini" @click="handleDialog">我已知悉上述法律责任并签订协议</el-button>
        </p>

        <!-- 补充协议签订按钮 -->
        <p class="ml-20" v-if="taskDetail.taskData.replenish_protocol.state === 4">
            <el-button size="mini" @click="handleSignExtraProtocol">签订补充协议</el-button>
        </p>
    </div>
</template>

<script>
import {
    getProtocolContent, // 获取协议
    getExtraProtocolByTaskNo, // 获取补充协议
} from '@/api/task';

export default {
    name: 'task-protocol-component',
    inject: ['taskDetail'],
    data() {
        return {
            data: {}, // 协议数据
            // taskNo: this.taskNo, // 任务编号

            extraProtocol: {}, // 补充协议
        };
    },
    props: {
        // extraProtocol: {
        //     type: Object,
        //     default: () => {
        //         return {};
        //     },
        // },
    },

    mounted() {
        this.init();
    },
    computed: {
        taskNo() {
            return this.$route.params.taskNo;
        },
    },

    methods: {
        init() {
            // 获取协议内容, 内部设计师无需获取
            if (this.taskDetail.taskData.designer_type !== 1) {
                this.initProtocol();
            }
            this.getExtraProtocolByTaskNo();
        },
        // 打开弹框
        handleDialog() {
            if (this.data.user_certify_state != 1)
                return this.$message({
                    showClose: true,
                    message: '请微信搜索【洛客设计师服务】进行实名认证操作，以便于生成电子合同',
                    duration: 0,
                });

            if (this.data.fadada_user_sign_status) {
                this.handleConfirm();
            } else {
                this.$confirm('当前账号未完成实名认证，是否前往实名认证？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    window.open(this.data.fadada_user_sign);
                    this.$confirm('完成实名认证后，点击刷新，重新签订协议', '提示', {
                        confirmButtonText: '刷新',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.data = {};
                        this.init();
                    });
                });
            }
        },

        // 显示协议已签订
        handleConfirm() {
            window.open(this.data.file_to_sign);
            this.$confirm('完成签约后，点击刷新，重新获取任务状态', '提示', {
                confirmButtonText: '刷新',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$emit('init');
            });
        },

        // 签订补充协议
        handleSignExtraProtocol(done) {
            if (this.extraProtocol.user_certify_state != 1)
                return this.$message({
                    showClose: true,
                    message: '请微信搜索【洛客设计师服务】进行实名认证操作，以便于生成电子合同',
                    duration: 0,
                });
            if (this.extraProtocol.fadada_user_sign_status) {
                // 走法大大
                window.open(this.extraProtocol.file_to_sign);
                this.$confirm('完成签约后，点击刷新，重新获取任务状态', '提示', {
                    confirmButtonText: '刷新',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    done();
                    this.$emit('init');
                });
            } else {
                this.$confirm('当前账号未完成实名认证，是否前往实名认证？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    window.open(this.extraProtocol.fadada_user_sign);
                    this.$confirm('完成实名认证后，点击刷新，重新签订协议', '提示', {
                        confirmButtonText: '刷新',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.extraProtocol = {};
                        this.init();
                    });
                });
            }
        },

        // 获取协议
        initProtocol() {
            if (this.taskDetail.taskData.protocol.state === 4) {
                // 如果有主协议待签约，则请求协议详情接口，该接口会调用法大大
                getProtocolContent(this.taskNo).then(([response, error]) => {
                    if (!error) {
                        this.data = response.data;
                    } else {
                        this.$message(error.msg);
                    }
                });
            }
        },

        // 有补充协议待签约，获取补充协议详情，该接口调用了法大大
        getExtraProtocolByTaskNo() {
            // 如果已经请求过，补充协议不为空，则不请求
            if (this.taskDetail.taskData.replenish_protocol.state === 4) {
                getExtraProtocolByTaskNo(this.taskNo).then(([response, error]) => {
                    if (!error) {
                        this.extraProtocol = response.data;
                    } else {
                        this.$message.error(error.msg);
                    }
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variable.scss';
@import '../styles';
</style>
