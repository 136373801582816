<template>
    <div>
        <ul class="proposal-images">
            <li
                class="proposal-images-item"
                :class="{ active: item.status == 1 }"
                v-for="(item, index) in images"
                :key="index"
            >
                <img :src="$fillOssPath(item.image)" @click="handlePreview(item.image)" />
                <span class="active-flag">客户选中</span>
            </li>
        </ul>
        <el-image
            style="height: 0; width: 0; position: absolute; z-index: -1"
            ref="preview"
            :src="currentPreviewImage"
            :preview-src-list="previewList"
        ></el-image>
    </div>
</template>

<script>
export default {
    name: 'component-proposal-images',
    props: {
        images: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        previewList() {
            return this.images.map((item) => this.$fillOssPath(item.image));
        },
    },
    data() {
        return {
            currentPreviewImage: '',
        };
    },
    methods: {
        handlePreview(url) {
            this.currentPreviewImage = this.$fillOssPath(url);
            const image = new Image();
            image.src = this.currentPreviewImage;
            image.onload = () => {
                setTimeout(() => {
                    this.$refs.preview.$el.getElementsByTagName('img')[0].click();
                });
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.proposal-images {
    &-item {
        position: relative;
        margin: 0 8px 8px 0;
        display: inline-block;
        width: 148px;
        height: 148px;
        border: 1px solid #c0ccda;
        border-radius: 6px;
        overflow: hidden;
        img {
            cursor: pointer;
            width: 100%;
            height: 100%;
        }
        &.active .active-flag {
            position: absolute;
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            left: 0;
            bottom: 0;
            background: #6cca76;
            color: #fff;
        }
    }
}
</style>
