<template>
    <div>
        <el-upload
            multiple
            :limit="limit"
            :accept="accept"
            :action="sign.host || ''"
            :data="ossData"
            :file-list="fileList"
            :before-upload="handleBeforeUpload"
            :on-change="handleChange"
            :on-exceed="handleExceed"
        >
            <el-button size="small" type="primary">点击上传</el-button>
            <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
        </el-upload>
    </div>
</template>

<script>
import { getOssSign } from '@/api/common';
import { replaceWhiteSpace } from '@/utils/common';

export default {
    name: 'component-multiple-upload',
    props: {
        accept: {
            type: String,
            default: '',
        },
        // 文件大小限制，单位为 MB
        size: {
            type: Number,
        },
        tip: {
            type: String,
        },
        // 传入的list，无需补全完整路径，会自动补全
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        limit: {
            type: Number,
            default: 999,
        },
    },

    watch: {
        list: {
            handler(value) {
                this.fileList = value.map((item) => {
                    return {
                        name: item.name,
                        url: process.env.VUE_APP_OSS_HOST + item.url,
                    };
                });
            },
            immediate: true,
        },
    },

    data() {
        return {
            sign: {},
            ossData: {},
            fileList: [],

            /*
                阿里云上传图片不会返回图片地址。因此上传之前，会先保存图片地址，成功后再从此读取
                格式  uid: url
            */
            urlMapping: {},

            showMsg: false,
        };
    },
    async mounted() {
        await this.initOssData();
    },
    methods: {
        // 初始化
        async initOssData() {
            const [response, error] = await getOssSign();

            if (!error) {
                this.sign = response.data.sign;

                let {
                    host,
                    policy,
                    signature,
                    expired_in,
                    accessid: OSSAccessKeyId,
                } = response.data.sign;

                // host 以 / 结尾，则去掉斜杠
                if (host.endsWith('/')) {
                    this.sign.host = host.substring(0, host.length - 1);
                }

                // 根据本机时间设置过期时间
                this.sign.expire = Date.now() + expired_in * 1000;

                this.ossData = {
                    policy,
                    signature,
                    OSSAccessKeyId,
                };
            } else {
                this.$message.error(error.msg);
            }
        },
        async handleBeforeUpload(file) {
            // 检测文件格式
            if (this.accept) {
                const accepts = this.accept.split(',');
                const isValid = accepts.find((item) => {
                    return file.name.toLocaleLowerCase().endsWith(item.toLocaleLowerCase().trim());
                });
                if (!isValid) {
                    this.$message.error('格式错误！');
                    return Promise.reject();
                }
            }

            // 检测文件大小
            if (this.size && this.size * 1024 * 1024 < file.size) {
                if (!this.showMsg) {
                    this.showMsg = true;
                    this.$message({
                        message: '尺寸超出限制',
                        type: 'error',
                        onClose: () => {
                            this.showMsg = false;
                        },
                    });
                }
                return Promise.reject();
            }

            await this.initOssData();

            // 防止文件重名
            const dir = this.sign.dir;
            const now = Date.now();
            this.ossData.key = dir + '/' + now + '-' + replaceWhiteSpace(file.name);

            // 将图片 uid 和 其路径进行保存
            this.urlMapping[file.uid] = {
                name: now + '-' + file.name,
                url: '/' + this.ossData.key,
            };
            return Promise.resolve();
        },
        // 格式化 fileList
        formatFileList(fileList) {
            const result = [];
            for (let i = 0; i < fileList.length; i++) {
                const item = fileList[i];
                if (item.raw) {
                    if (item.status !== 'success') {
                        this.$message.warning('请等待文件上传完成');
                        return false;
                    }
                    result.push(this.urlMapping[item.uid]);
                } else {
                    result.push({
                        name: item.name,
                        url: item.url.replace(process.env.VUE_APP_OSS_HOST, ''),
                    });
                }
            }
            return result;
        },
        handleChange(file, fileList) {
            this.fileList = fileList;
            this.$emit('change', fileList);
        },

        handleExceed() {
            this.$message.warning(`超出个数限制，最多允许上传${this.limit}个文件`);
        },
        getFiles() {
            return this.formatFileList(this.fileList);
        },
    },
};
</script>
