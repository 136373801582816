/**
 * @description 任务相关接口
 */
import request from '@/utils/request';

export function getTaskDetail(id) {
    return request({
        url: `/v1/task/${id}`,
        method: 'get',
    });
}

// 获取提案详情
export function getDeliveryDetail(taskNo) {
    return request({
        url: `/v1/task/${taskNo}/delivery`,
        method: 'get',
    });
}

/**
 * 签订协议
 * @param {*} id 任务编号
 * @returns
 * @deprecated 法大大签章不在使用这个接口
 */
export function putProtocolConfirm(id) {
    return request({
        url: `/v1/task/${id}/sign-protocol`,
        method: 'put',
    });
}

/**
 * 获取协议
 * @param {*} id 任务编号
 * @returns
 */
export function getProtocolContent(id) {
    return request({
        url: `/v1/task/${id}/protocol`,
        method: 'get',
    });
}

// 提交交付物
export function postTaskDelivery(taskNo, data) {
    return request({
        url: `/v1/task/${taskNo}/delivery`,
        method: 'post',
        data,
    });
}

/**
 *  获取项目信息
 * @param {Number | String} id
 */
export function getProjetInfo(id) {
    return request({
        url: `/v1/task/${id}/launch-info`,
        method: 'get',
    });
}

// 获取提案信息
export function getTaskProposal(taskNo) {
    return request({
        url: `/v1/task/${taskNo}/proposal`,
        method: 'get',
    });
}

/**
 * 提交提案
 * @param {Number | String} id 提案 id
 * @param {Object} data 请求体
 * @param {Array} data.images 提案缩略图
 * @param {String} data.file 提案文件的地址
 */
export function postTaskProposal(id, data) {
    return request({
        url: `/v1/task/proposal/${id}`,
        method: 'post',
        data,
    });
}

/**
 * 撤回提案
 * @param {Number | String} id 提案 id
 */
export function putTaskProposalCancel(id) {
    return request({
        url: `/v1/task/proposal/${id}/cancel`,
        method: 'put',
    });
}

/**
 * 根据任务id获取补充协议
 * @param {} taskNo
 * @returns
 */
export function getExtraProtocolByTaskNo(taskNo) {
    return request({
        url: `/v1/task/${taskNo}/protocol/replenish`,
    });
}
