<template>
    <div v-if="proposalList.length">
        <div class="module-item mb-40" v-for="(proposal, index) in proposalList" :key="proposal.id">
            <div class="module-title mb-10">
                <div>{{ proposal.title }}</div>
                <div>{{ formatProposalItemState(proposal) }}</div>
            </div>
            <i class="module-line"></i>
            <div class="module-content">
                <ul class="title-list">
                    <li class="list-content">
                        请在
                        {{ dateFormat(proposal.expected, 'yyyy年MM月dd日') }}
                        前上传方案缩略图及提案文件
                    </li>
                </ul>
                <el-form label-width="90px" label-position="left" class="mt-20 plForm">
                    <!-- 多图上传
                            @change="handleUploadChange($event, proposal.id)"-->
                    <el-form-item label="方案缩略图">
                        <lk-multiple-image-upload
                            v-if="proposal.item_state === 0 || proposal.item_state === 3"
                            :limit="4"
                            accept=".jpg, .jpeg, .png"
                            :ref="'upload' + proposal.id"
                            :list="proposalImageMap[proposal.id]"
                            :size="3"
                        >
                        </lk-multiple-image-upload>
                        <proposal-images v-else :images="proposal.images"></proposal-images>
                    </el-form-item>
                    <el-form-item label="提案文件">
                        <!-- 设计任务 -->
                        <template v-if="isIDTask">
                            <lk-singlefile-upload
                                v-if="proposal.item_state === 0 || proposal.item_state === 3"
                                text="提案文件上传"
                                :value="proposal.files[0]"
                                accept=".ppt, .pptx, .pdf, .jpg, .jpeg, .png"
                                :size="100"
                                @input="handleFileUploaded($event, proposal)"
                            ></lk-singlefile-upload>
                            <template v-else>
                                <el-link
                                    v-for="file in proposal.files"
                                    style="display: block"
                                    :key="file"
                                    type="primary"
                                    target="_blank"
                                    :href="$fillOssPath(file)"
                                >
                                    {{ file | formatFileName }}
                                </el-link>
                            </template>
                        </template>
                        <!-- 结构任务 -->
                        <template v-if="isMDTask">
                            <lk-multiple-file-upload
                                v-if="proposal.item_state === 0 || proposal.item_state === 3"
                                text="提案文件上传"
                                :limit="3"
                                accept=".jpg, .jpeg, .ppt, .pptx, .pdf, .png, .zip, .rar"
                                :size="1024"
                                tip="支持格式 .jpg, .jpeg, .ppt, .pptx, .pdf, .png, .zip, .rar,大小限制1GB"
                                :ref="'uploadFile' + proposal.id"
                            ></lk-multiple-file-upload>
                            <template v-else>
                                <el-link
                                    style="display: block"
                                    v-for="item in proposal.files"
                                    :key="item"
                                    type="primary"
                                    target="_blank"
                                    :href="$fillOssPath(item)"
                                >
                                    {{ item | formatFileName }}
                                </el-link>
                            </template>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            v-if="proposal.item_state === 0 || proposal.item_state === 3"
                            type="primary"
                            size="small"
                            @click="handleSubmit(proposal.id, index)"
                        >
                            提交审核
                        </el-button>
                        <el-button
                            v-if="proposal.item_state === 1"
                            type="primary"
                            size="small"
                            @click="handleCancelProposal(proposal.id)"
                        >
                            撤回审核
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-form v-if="proposal.item_state === 3">
                    <el-form-item label="邦女郎反馈建议">
                        <!-- <el-input
                            disabled
                            type="textarea"
                            :rows="5"
                            :value="proposal.remark"
                        ></el-input> -->
                        <div
                            class="tips-content mt-15 fs-14"
                            style="display: inline-block; width: 100%"
                        >
                            {{ proposal.remark }}
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- 规则提示 -->
        <div class="tips-content mt15">
            <div class="fs-14 mb-10">【方案效果图规则】</div>
            <p>1、方案效果图将供客户选择，请每款方案上传一张清晰的效果图即可</p>
            <p>2、效果图建议尺寸为 600px * 600px</p>

            <div class="fs-14 mt-20 mb-10">【提交文件规则】</div>
            <p>
                1、文件模版请点击下载
                <a
                    href="https://lkker-lady-next.oss-cn-beijing.aliyuncs.com/designer-workbench/resource/%E6%B4%9B%E5%AE%A2%E6%8F%90%E6%A1%88%E6%A1%86%E6%9E%B6%E6%A8%A1%E7%89%88.pptx"
                    target="_blank"
                    style="color: #409eff"
                >
                    提案模版
                </a>
            </p>
            <p>
                2、文件命名为“产品名称+方案类型（序号）+设计师昵称”，例如：“无人机-草图提案1-某某某
            </p>
            <p v-if="isIDTask">3、文件大小不能超过100M</p>
            <p v-if="isMDTask">3、文件大小不能超过1024M</p>
        </div>
    </div>
</template>

<script>
import LkSinglefileUpload from '@/components/LkSinglefileUpload';
import LkMultipleImageUpload from '@/components/LkMultipleImageUpload';
import LkMultipleFileUpload from '@/components/LkMultipleFileUpload';

import ProposalImages from './ProposalImages';

import { getTaskProposal, postTaskProposal, putTaskProposalCancel } from '@/api/task';
import { dateFormat } from '@/utils/common';
import { PROPOSAL_ITEM_STATE } from '@/constant/task';

export default {
    name: 'task-protocol-proposal',
    components: { LkSinglefileUpload, LkMultipleImageUpload, LkMultipleFileUpload, ProposalImages },
    inject: ['taskDetail'],
    data() {
        return {
            proposalList: [], // 提案数组

            proposalImageMap: {}, // 整理为
        };
    },
    computed: {
        // 是否是设计任务
        isIDTask() {
            return this.taskDetail.taskData.task_type === 0;
        },
        // 是否是结构任务
        isMDTask() {
            return this.taskDetail.taskData.task_type === 1;
        },
    },
    mounted() {
        this.initProposal();
    },
    methods: {
        // 初始化提案数据
        initProposal() {
            getTaskProposal(this.taskDetail.taskNo).then(([response, error]) => {
                if (!error) {
                    this.proposalList = response.data;
                    const imageMap = {};
                    this.proposalList.forEach((proposal) => {
                        imageMap[proposal.id] = proposal.images.map((item) => {
                            return {
                                url: item.image,
                                name: this.$options.filters.formatFileName(item.image),
                            };
                        });
                    });
                    this.proposalImageMap = imageMap;
                }
            });
        },
        // 提交提案
        handleSubmit(proposalId, index) {
            let files;
            // 结构任务使用的是多文件上传，需提交前获取
            if (this.isMDTask) {
                files = this.$refs['uploadFile' + proposalId][0].getFiles().map((item) => item.url);
            }
            if (this.isIDTask) {
                files = this.proposalList[index].files;
            }

            let images;
            try {
                images = this.$refs['upload' + proposalId][0].getFiles().map((item) => item.url);
            } catch (error) {
                return;
            }
            if (!files || !files.length) {
                return this.$message.error('请上传提案文件');
            }

            if (!images.length) {
                return this.$message.error('请上传方案缩略图');
            }
            this.$lkConfirm({
                message: '提交后平台将进行审核',
                type: 'info',
                onConfirm: (done) => {
                    const data = { images, files };

                    postTaskProposal(proposalId, data).then(([, error]) => {
                        if (!error) {
                            this.$emit('init');
                            this.$message.success('提案提交成功');
                        } else {
                            this.$message.error(error.msg);
                        }
                        done();
                    });
                },
            });
        },
        // 缩略图上传文件发生改变时触发
        handleUploadChange(fileList, id) {
            this.proposalImageMap[id] = fileList;
        },
        // 撤回申请
        handleCancelProposal(proposalId) {
            this.$lkConfirm({
                message: '撤回后邦女郎将重新审核',
                type: 'warning',
                onConfirm: (done) => {
                    putTaskProposalCancel(proposalId).then(([, error]) => {
                        if (!error) {
                            this.$message.success('提案已撤回');
                            this.$emit('init');
                        } else {
                            this.$message.error(error.msg);
                        }
                        done();
                    });
                },
            });
        },

        // 提案文件上传成功
        handleFileUploaded(url, proposal) {
            this.$set(proposal.files, 0, url);
        },

        handleShowList() {
            this.$message(this.$refs.abc.getFiles());
        },
        formatProposalItemState(proposal) {
            console.log(proposal);
            if (proposal.item_state === 4) {
                const selected = proposal.images.find((item) => item.status == 1);
                return selected ? '已确认' : '未入选';
            } else {
                return PROPOSAL_ITEM_STATE[proposal.item_state];
            }
        },
        dateFormat,
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/variable.scss';
@import '../styles';
.plForm {
    padding-left: 20px;
}
.tips-content {
    background: #f6f6f6;
    border-radius: 10px;
    padding: 25px;
    color: #5a5a5a;
    p {
        font-size: 12px;
        line-height: 1.4;
    }
}
</style>
