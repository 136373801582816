<template>
    <div class="init-container">
        <!-- init -->
        <div class="module-title mb-10">项目启动</div>
        <i class="module-line"></i>

        <ul class="title-list">
            <!-- status: 0 待签约 1 已签约 2 设计中 3 方案确认 4 强制结项 5 正常结项 -->
            <li class="list-content">请等待邦女郎启动项目及上传相关资料</li>
            <li class="list-content mt-10" v-if="data.status >= 2">
                项目已启动，邦女郎已上传立项说明书和相关参考资料，请下载查看
            </li>
        </ul>

        <!-- 任务文件 -->
        <div v-if="isShowProjectFile">
            <!-- 设计任务文件 -->
            <template v-if="isIDTask">
                <p class="init-link">
                    <span class="mr-5 file-name">立项说明书：</span>
                    <el-link
                        type="primary"
                        :underline="false"
                        target="_blank"
                        :href="$fillOssPath(taskFiles[1].file)"
                    >
                        {{ data.file[0].file | formatFileName }}
                    </el-link>
                </p>
                <p class="init-link mt-10">
                    <span class="mr-5 file-name">会议纪要：</span>
                    <el-link
                        type="primary"
                        :underline="false"
                        target="_blank"
                        :href="$fillOssPath(taskFiles[1].file)"
                    >
                        {{ data.file[1].file | formatFileName }}
                    </el-link>
                </p>
            </template>
            <!-- 结构任务 -->
            <template v-if="isMDTask">
                <p class="init-link mt-10">
                    <span class="mr-5 file-name">项目资料：</span>
                    <el-link
                        type="primary"
                        :underline="false"
                        target="_blank"
                        :href="$fillOssPath(taskFiles[5].file)"
                    >
                        {{ data.file[1].file | formatFileName }}
                    </el-link>
                </p>
            </template>
        </div>
    </div>
</template>
<script>
// 1 立项说明书 2 会议纪要 3 需求说明书 4 项目说明书 5 项目资料
import {
    getProjetInfo, // 获取项目信息
} from '@/api/task';
export default {
    name: 'task-protocol-init',
    inject: ['taskDetail'],
    data() {
        return {
            data: {},
            taskNo: this.taskDetail.taskNo, // 任务编号
        };
    },
    computed: {
        // 2 设计中  3 方案确认 4 强制结项 5 正常结项
        isShowProjectFile() {
            return [2, 3, 4, 5].indexOf(this.data.status) > -1;
        },
        // 是否是结构任务
        isMDTask() {
            return this.taskDetail.taskData.task_type === 1;
        },
        // 是否是设计任务
        isIDTask() {
            return this.taskDetail.taskData.task_type === 0;
        },
        // 项目文件列表更改为 type => fileInfo 的格式
        taskFiles() {
            const files = {};
            this.data.file.forEach((file) => {
                files[file.type] = file;
            });
            return files;
        },
    },

    created() {
        this.initProjetInfo();
    },

    methods: {
        // 获取项目信息
        initProjetInfo() {
            getProjetInfo(this.taskDetail.taskNo).then(([response, error]) => {
                if (!error) {
                    this.data = response.data;
                } else {
                    this.$message.error(error.msg);
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variable.scss';
@import '../styles';

.init-link {
    padding-left: 20px;
    .el-link {
        line-height: 22px;
    }
    .file-name {
        display: inline-block;
        width: 100px;
    }
}
</style>
