<script>
import TaskProgress from './components/TaskProgress';
import TaskProtocol from './components/TaskProtocol';
import TaskInit from './components/TaskInit';
import TaskProposal from './components/TaskProposal';
import TaskDelivery from './components/TaskDelivery';

import { getTaskDetail } from '@/api/task';

import { strToTimestamp } from '@/utils/common';

export default {
    name: 'task-detail',
    components: { TaskProgress, TaskProtocol, TaskInit, TaskProposal, TaskDelivery },
    provide() {
        return {
            taskDetail: this,
        };
    },
    computed: {
        taskNo() {
            return this.$route.params.taskNo;
        },
    },
    data() {
        return {
            activitedMoudel: '', // 当前被激活的模块

            taskData: {
                title: '', // 任务名称
                category_id: '', // 分类id
                price: '', // 任务酬金(采购单总金额)
                expected: '', // 结项时间
                purchase_order_id: '', // 采购单id
                created_at: '', //采购单创建时间
                task_status: '', // 任务状态 0 待签约 1 已签约 2 设计中 3 方案确认 4 强制结项 5 正常结项
                state: '', // 采购单状态
                check_type: '', // 采购单选中类型 0 待选 1 中标 2 落选
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        // 初始化，获取当前采购单信息和采购单对应任务的信息
        init() {
            this.activitedMoudel = '';
            getTaskDetail(this.taskNo).then(([response, error]) => {
                if (!error) {
                    document.title = `我的项目-${response.data.title}`;

                    this.taskData = response.data;
                    const { state, check_type } = response.data;

                    // 如果有补充协议 待签约，则获取补充协议信息
                    if (this.taskData.replenish_protocol.state === 4) {
                        // 该任务有补充协议待签约
                        this.$confirm('已为您生成《补充协议》，请您查阅后签订', '提示', {
                            confirmButtonText: '立即签订',
                            cancelButtonText: '稍后处理',
                        }).then(() => {
                            this.activitedMoudel = 'protocol';
                        });
                    }

                    // 根据任务单状态状态，设置当前默认显示的步骤
                    switch (state) {
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            return (this.activitedMoudel = 'protocol');
                        case 4:
                            return (this.activitedMoudel = 'init');
                        case 5:
                        case 6:
                            return (this.activitedMoudel =
                                check_type === 0 ? 'proposal' : 'delivery');
                    }
                } else {
                    this.$message.error(error.msg);
                }
            });
        },
        // 切换当前应该显示的模块
        handleStepChange(moduleName) {
            this.activitedMoudel = moduleName;
        },
        // 采购单选中状态
        purchaseState(state, check_type) {
            switch (state) {
                case 0:
                case 1:
                case 2:
                case 3:
                    return '待签约';
                case 4:
                    return '待启动';
                case 5:
                    return check_type === 0 ? '设计中' : '交付中';
                case 6:
                    return '已完成';
            }
        },
    },

    render() {
        // 初始化事件
        const initEvent = {
            on: {
                init: this.init,
            },
        };

        // 剩余天数
        const getRemaining = () => {
            if (this.taskData.task_status < 2) {
                return <span>-- 天</span>;
            }

            const expected = this.taskData.expected;

            // 结项时间未获取，返回象征空的字符串
            if (!expected && typeof expected !== 'number') {
                return <span> -- </span>;
            }

            const remaining = strToTimestamp(expected) - Date.now();

            if (remaining < 0) {
                return (
                    <span class="color-red">逾期{Math.ceil(Math.abs(remaining) / 864e5)}天</span>
                );
            }
            return (
                <span class="color-fbc">
                    {Math.ceil(Math.abs(remaining) / 864e5)}
                    <span>天</span>
                </span>
            );
        };

        return (
            <div class="task-detail-container">
                {/* 任务详情 */}
                <div class="task-detail-header card-box">
                    <div class="task-detail-box">
                        <span class="fs-20 fw-blod">
                            {this.taskData.title}
                            {this.taskData.check_type === 1 && (
                                <i class="ml-10 bid fs-12">已中标</i>
                            )}
                        </span>
                        <span class="colorBlue fs-18 fw-blod">
                            {this.purchaseState(this.taskData.state, this.taskData.check_type)}
                        </span>
                    </div>
                    <div class="task-detail-box mt-20">
                        <span class="fs-16">
                            <span>任务分类：</span>
                            <span class="fw-blod">
                                {this.taskData.category_name === null ? (
                                    <span>{this.taskData.sku_label}</span>
                                ) : (
                                    <span>
                                        {this.taskData.category_name} -
                                        {this.taskData.sub_category_name}
                                    </span>
                                )}
                            </span>

                            <span class="ml-40">任务酬金：</span>
                            <span class="fw-blod">
                                {this.$options.filters.price(this.taskData.price)} 元
                            </span>
                        </span>
                        {this.taskData.state !== 6 && (
                            <span class="fs-16">
                                剩余天数：
                                {getRemaining()}
                            </span>
                        )}
                    </div>
                    <i class="task-detail-icon"></i>
                    <div>
                        <span class="fs-14 color-gray">
                            采购单ID {this.taskData.purchase_order_id}
                        </span>
                        <span class="ml-40 fs-14 color-gray">
                            采购单创建时间 {this.taskData.created_at}
                        </span>
                    </div>
                </div>

                {/* 任务主体 */}
                <div class="task-detail-main card-box mt-20">
                    {/* 当前进度 模块展示/选择 */}
                    <task-progress class="mb-30" on={{ change: this.handleStepChange }} />

                    {this.activitedMoudel === 'protocol' && (
                        <task-protocol {...initEvent}></task-protocol>
                    )}

                    {this.activitedMoudel === 'init' && <task-init {...initEvent}></task-init>}

                    {this.activitedMoudel === 'proposal' && (
                        <task-proposal {...initEvent}></task-proposal>
                    )}

                    {this.activitedMoudel === 'delivery' && (
                        <task-delivery {...initEvent}></task-delivery>
                    )}
                </div>
            </div>
        );
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variable.scss';
.card-box {
    padding: 32px 90px;
    background: #fff;
}
.task-detail-box {
    display: flex;
    justify-content: space-between;
    .bid {
        font-weight: normal;
        font-style: normal;
        display: inline-block;
        width: 60px;
        height: 22px;
        background: #6cca76;
        color: #fff;
        line-height: 22px;
        text-align: center;
        border-radius: 4px;
    }
}
.color-fbc {
    color: #fbc03c;
}
.color-red {
    color: #e88989;
}
.fw-blod {
    font-weight: bold;
}
.color-gray {
    color: #999;
}
.colorBlue {
    color: $blue;
}
.task-detail-icon {
    display: block;
    height: 1px;
    border: 1px solid #ebecf0;
    margin: 30px auto;
}
.task-detail-container {
    width: 1190px;
    margin: 0 auto;
    padding: 40px 0;
    .task-detail-header {
        height: 228px;
        border-radius: 30px;
    }
    .task-detail-main {
        border-radius: 30px;
        min-height: calc(100vh - 330px);
    }
}
</style>
