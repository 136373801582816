<script>
import LkMultipleFileUpload from '@/components/LkMultipleFileUpload';
export default {
    name: 'delivery-upload-item',
    components: { LkMultipleFileUpload },
    props: {
        deliveryRule: {
            type: Object,
            required: true,
        },
        id: {
            type: [String, Number],
        },
        delivery: {
            type: Array,
            default() {
                return [];
            },
            required: true,
        },
    },
    watch: {
        // 数据更新
        delivery: {
            handler(value) {
                const list = JSON.parse(JSON.stringify(value));
                this.list = list.map((item) => {
                    return {
                        name: this.$options.filters.formatFileName(item),
                        url: item,
                    };
                });
                this.mirror = JSON.parse(JSON.stringify(this.list));
            },
            immediate: true,
        },
    },
    data() {
        return {
            list: [],
            mirror: [], // 实时接收上传组件的更改信息，用于当前组件校验上传是否正确，也用于提交给父组件
        };
    },
    methods: {
        getData() {
            return this.$refs.upload.getFiles();
        },
        handleChange(fileList) {
            this.mirror = fileList;
        },
    },
    render() {
        const { file_size, file_types, max_file_num, min_file_num } =
            this.deliveryRule.restrictions;

        // form-item配置
        const formItemData = {
            props: {
                prop: this.id,
                label: this.deliveryRule.display_name,
                // 为避免用户逐步上传文件式频繁提示错误，此处只做非空校验，交付数量是否符合要求最后提交前进行校验
                rules: {
                    validator: (rule, value, callback) => {
                        if (this.deliveryRule.is_required === 0) return callback();
                        if (!this.mirror.length) {
                            callback(new Error('请上传交付物文件'));
                        } else if (this.mirror.length < min_file_num) {
                            callback(new Error(`请上传至少${min_file_num}个交付物文件`));
                        }
                        callback();
                    },
                    message: '请上传交付物文件',
                    required: !!this.deliveryRule.is_required,
                    trigger: 'change',
                },
            },
        };

        // 区分单文件和多文件上传时的文案
        const fileNumTip =
            max_file_num === 1
                ? '需要上传 1 个交付文件'
                : `需要上传 ${min_file_num}-${max_file_num} 个交付文件。`;
        // 服务端存储的文件类型修改
        const fileTypes = file_types.map((type) => `.${type}`);
        const accept = fileTypes.join(', ');

        // 上传组件配置
        const uploadData = {
            props: {
                accept,
                size: ~~file_size,
                limit: max_file_num,
                tip: `允许的上传格式: ${accept}，文件大小不得超过${file_size}MB，${fileNumTip}`,
                list: this.list,
            },
            on: {
                change: this.handleChange,
            },
        };
        return (
            <el-form-item {...formItemData} key={this.id}>
                <lk-multiple-file-upload ref="upload" {...uploadData}></lk-multiple-file-upload>
            </el-form-item>
        );
    },
};
</script>
