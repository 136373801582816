<template>
    <div class="singilefile-upload-content">
        <div class="upload-box">
            <el-link
                v-if="value && !loading"
                size="medium"
                type="info"
                :href="href"
                target="_blank"
                :underline="false"
                class="mr-10"
            >
                {{ currentFile.name }}
            </el-link>
            <el-upload
                :action="sign.host || ''"
                :data="ossData"
                :limit="1"
                :accept="accept"
                :show-file-list="false"
                :before-upload="handleBeforeUpload"
                :on-success="handleSuccess"
                :on-error="handleError"
                :disabled="loading || disabled"
                ref="uploader"
            >
                <el-button type="text" slot="trigger" v-show="!disabled">
                    <i v-show="loading" class="el-icon-loading"></i>
                    <i v-show="!loading" class="iconfont icon-upload"></i>
                    {{ loading ? '上传中...' : !value ? text : '重新上传' }}
                </el-button>
            </el-upload>
        </div>
        <div class="tips">
            <template v-if="accept">
                支持扩展名：{{ accept.trim().split(',').join(' ') }}
            </template>
            <template v-if="accept && size">,</template>
            <template v-if="size"> 文件大小不能超过{{ size }}M </template>
        </div>
    </div>
</template>

<script>
import { getOssSign } from '@/api/common';
import { replaceWhiteSpace } from '@/utils/common';

export default {
    name: 'component-single-file-upload',
    props: {
        value: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '上传',
        },
        accept: {
            type: String,
            default: '',
        },
        // 文件大小限制，单位为 MB
        size: {
            type: Number,
        },
        disabled: Boolean,
        //  1:task 任务(默认)    2:opus  作品
        ossType: {
            type: [Number, String],
            default: '',
        },
    },
    data() {
        return {
            loading: false,
            ossData: {},
            sign: {
                host: '',
            },
            currentFile: {
                name: '',
                fullPath: '',
            },
        };
    },
    watch: {
        value: {
            handler(value) {
                if (!value) return;
                const arr = value.split('/');
                this.currentFile.name = arr[arr.length - 1];
                this.currentFile.fullPath = process.env.VUE_APP_OSS_HOST + value;
            },
            immediate: true,
        },
    },
    computed: {
        href() {
            return process.env.VUE_APP_OSS_HOST + this.value;
        },
    },
    methods: {
        handleBeforeUpload(file) {
            if (this.size && this.size * 1024 * 1024 < file.size) {
                this.$message.error(`文件尺寸超出限制，应小于等于 ${this.size} MB`);
                return false;
            }
            this.loading = true;
            this.ossData = {};
            this.sign = {};

            return new Promise((resolve, reject) => {
                getOssSign(this.ossType).then(([response, error]) => {
                    if (!error) {
                        this.currentFile = {};

                        this.sign = response.data.sign;

                        let { host, policy, dir, signature, accessid: OSSAccessKeyId } = this.sign;

                        // host 以 / 结尾，则去掉斜杠
                        if (host.endsWith('/')) {
                            host = host.substring(0, host.length - 1);
                        }

                        // 防止文件重名
                        this.ossData = {
                            key: dir + '/' + Date.now() + '-' + replaceWhiteSpace(file.name),
                            policy,
                            signature,
                            OSSAccessKeyId,
                        };

                        resolve();
                    } else {
                        this.$message.error(error.msg);
                        reject();
                    }
                });
            });
        },
        handleSuccess(/* response, file */) {
            this.$emit('input', `/${this.ossData.key}`);
            this.$emit('on-success', `/${this.ossData.key}`);
            this.$refs.uploader.clearFiles();
            this.loading = false;
        },
        handleError(error) {
            this.$message.error(error.msg);
            this.loading = false;
        },
    },
};
</script>

<style scoped>
.singilefile-upload-content {
    min-height: 40px;
    display: inline-block;
    vertical-align: top;
}
.upload-box {
    display: flex;
}
.tips {
    font-size: 14px;
    color: #979797;
}
</style>
