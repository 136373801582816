var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"progress-content"},[_c('div',{staticClass:"progress",class:'step' + _vm.step},[_c('span',{staticClass:"circular",class:{ isShow: _vm.taskDetail.state === 6 }})])]),_c('div',{staticClass:"progress-move"},[_c('div',{staticClass:"progress-box",on:{"click":function($event){return _vm.handleChange('protocol', true)}}},[_c('i',{staticClass:"icon"}),_c('span',{staticClass:"text",class:{
                    active: _vm.activitedMoudel === 'protocol',
                }},[_vm._v(" 签订协议 ")])]),_c('div',{staticClass:"progress-box",on:{"click":function($event){return _vm.handleChange('init', _vm.initUseable)}}},[_c('i',{staticClass:"icon",class:{ disable: !_vm.initUseable }}),_c('span',{staticClass:"text",class:{
                    active: _vm.activitedMoudel === 'init',
                    disable: !_vm.initUseable,
                }},[_vm._v(" 项目立项 ")])]),_c('div',{staticClass:"progress-box",on:{"click":function($event){return _vm.handleChange('proposal', _vm.proposalUseable)}}},[_c('i',{staticClass:"icon",class:{ disable: !_vm.proposalUseable }}),_c('span',{staticClass:"text",class:{ active: _vm.activitedMoudel === 'proposal', disable: !_vm.proposalUseable }},[_vm._v(" 设计提案 ")])]),_c('div',{staticClass:"progress-box",on:{"click":function($event){return _vm.handleChange('delivery', _vm.deliveryUseable)}}},[_c('i',{staticClass:"icon",class:{ disable: !_vm.deliveryUseable }}),_c('span',{staticClass:"text",class:{
                    active: _vm.activitedMoudel === 'delivery',
                    disable: !_vm.deliveryUseable,
                }},[_vm._v(" 交付文件 ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }