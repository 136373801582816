<script>
import DeliveryUpload from './DeliveryUpload/index';
import { getDeliveryDetail, postTaskDelivery } from '@/api/task';
import { DELIVER_STATUS } from '@/constant/task';

import { dateFormat } from '@/utils/common';

export default {
    name: 'task-protocol-delivery',
    components: { DeliveryUpload },
    inject: ['taskDetail'],
    data() {
        return {
            deliveryRules: {},
            versions: {},

            // 1和2表示的是交付物类型
            // 当交付物被驳回时，更改下方对应状态，将上传组件开放，允许用户重新编辑交付物执行上传
            editVisible: {
                1: false,
                2: false,
            },
        };
    },
    mounted() {
        this.initDelivery();
    },
    methods: {
        initDelivery() {
            getDeliveryDetail(this.taskDetail.taskNo).then(([response, error]) => {
                if (!error) {
                    this.deliveryRules = response.data.deliveries;
                    this.versions = response.data.delivery_versions;

                    // 交付物上传组件是否显示
                    // 如果处于驳回状态，该属性为 false
                    // 则点击重新上传，将此属性设置为 true，则可进行编辑
                    Object.keys(this.versions).forEach((type) => {
                        const version = this.versions[type];
                        this.editVisible[type] = version.status === 0;
                    });
                }
            });
        },
        // 提交交付物
        handleSubmit(deliveries, type) {
            if (!deliveries) return;

            const h = this.$createElement;
            this.$msgbox({
                title: '本项目默认永久保密',
                showCancelButton: true,
                message: h('div', { class: 'confirm-box' }, [
                    '请您务必对本项目所有信息及交付物进行保密， 如您需要对外展示，须获得洛客平台书面允许方可操作。',
                    h('div', { class: 'confirm-tips' }, '客服电话：400-6997-088'),
                ]),
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = '上传中...';
                        const data = {
                            delivery_type: type,
                            version_id: this.versions[type].id,
                            deliveries,
                        };
                        postTaskDelivery(this.taskDetail.taskNo, data).then(([, error]) => {
                            if (!error) {
                                this.$emit('init');
                                this.$message.success('提交成功');
                            } else {
                                this.$message.error(error.msg);
                            }
                            done();
                            instance.confirmButtonLoading = false;
                        });
                    } else {
                        done();
                    }
                },
            });
        },

        // 重新上传
        handleReUpload(type) {
            this.editVisible[type] = true;
        },
    },
    render() {
        // 交付物类型
        const DELIVERY_TYPE = {
            1: '中标',
            2: '落选',
        };

        // 生成单个交付物 当 delivery_content 为字符串，则表明为文字交付物，为数组则表示是文件交付物
        const genDelivery = (delivery) => {
            // 当 content 为数组时, 生成交付物文件子项
            const genFileLink = (item, key) => {
                return (
                    <el-link
                        type="primary"
                        href={this.$fillOssPath(item)}
                        target="_blank"
                        underline={false}
                        key={key}
                        class="display-block"
                    >
                        {this.$options.filters.formatFileName(item)}
                    </el-link>
                );
            };

            const content = delivery.delivery_content;

            return (
                <el-form-item key={delivery.id} label={delivery.display_name}>
                    {Array.isArray(content) ? (
                        <div>
                            {content.map((item, index) => {
                                return <p>{genFileLink(item, index)}</p>;
                            })}
                        </div>
                    ) : (
                        content
                    )}
                </el-form-item>
            );
        };

        return (
            <div>
                {Object.keys(this.versions).map((type) => {
                    // 交付物版本
                    const version = this.versions[type];

                    // 交付物上传规则
                    const deliveryRule = this.deliveryRules[version.data_type];
                    // 将已经上传过交付物的数据，转化为上传组件能识别的格式
                    const deliveries = {};
                    version.designer_deliveries.forEach((deliverie) => {
                        const { delivery_id, delivery_content } = deliverie;
                        deliveries[delivery_id] = delivery_content;
                    });
                    // 上传交付物组件配置
                    const deliveryUploadData = {
                        props: {
                            deliveryRules: deliveryRule,
                            deliveries: deliveries,
                        },
                        on: {
                            submit: (deliveries) => {
                                this.handleSubmit(deliveries, version.data_type);
                            },
                        },
                    };

                    // 计算上传交付物的最后日期
                    const expected = dateFormat(version.expected, 'yyyy年MM月dd日');

                    return (
                        <div class="delivery-item mb-20" key={type}>
                            <div class="module-title mb-10">
                                <div class="dilivery-name">{DELIVERY_TYPE[type]}交付物</div>
                                <div class="dilivery-status">{DELIVER_STATUS[version.status]}</div>
                            </div>
                            <i class="module-line mb-20"></i>
                            {/* 交付物上传 当状态为待上传时显示 */}
                            {(version.status === 0 || this.editVisible[type]) && (
                                <delivery-upload
                                    {...deliveryUploadData}
                                    key={type}
                                ></delivery-upload>
                            )}

                            {/* 已上传交付物展示 */}
                            {version.status > 0 && !this.editVisible[type] && (
                                <div>
                                    {/* <div class="tips">
                                        请在{expected}之前上传{DELIVERY_TYPE[type]}交付物
                                    </div>
                                     */}
                                    <ul class="title-list mb-15">
                                        <li class="list-content">
                                            请在{expected}之前上传{DELIVERY_TYPE[type]}交付物
                                        </li>
                                    </ul>
                                    <el-form label-width="150px" label-position="left">
                                        {version.designer_deliveries.map((item) => {
                                            return genDelivery(item);
                                        })}
                                    </el-form>
                                </div>
                            )}

                            {/* 交付物被驳回 */}
                            {(version.status === 3 || version.status === 5) &&
                                !this.editVisible[type] && (
                                    <el-form label-width="150px" label-position="left">
                                        <el-form-item label="驳回原因">
                                            <el-input
                                                disabled={true}
                                                type="textarea"
                                                value={version.proposal}
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button
                                                type="primary"
                                                size="small"
                                                on={{
                                                    click: () => {
                                                        this.handleReUpload(type);
                                                    },
                                                }}
                                            >
                                                重新上传
                                            </el-button>
                                        </el-form-item>
                                    </el-form>
                                )}
                        </div>
                    );
                })}
            </div>
        );
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/variable.scss';
@import '../styles';

.el-form {
    padding-left: 20px;
}
.display-block {
    display: block;
}
.confirm-box {
    padding: 10px 0;
}
.confirm-tips {
    color: #a4a4a4;
    padding-top: 15px;
}

.reject-reason {
    &-title {
        font-size: 16px;
        font-weight: bold;
    }
    &-content {
        margin-top: 20px;
        padding: 12px;
        border-radius: 5px;
        border: 1px solid $borderColor;
        color: #acacac;
    }
}
</style>
